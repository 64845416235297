.dashboard {
  background-color: #0E0E0E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #000;
  margin-top: -75px;
}

.logo {
  width: 200px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
}

.voltar {
  background-color: red;
  border: none;
  color: yellow;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 20px;
}

.voltar:hover {
  background-color: #CC0000;
}

.report {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report label {
  color: white;
  margin-top: 10px;
}

.report select,
.report input,
.report button {
  margin-top: 10px;
}

.select, .input, .downloadButton {
  padding: 10px;
  font-size: 1rem;
}

.downloadButton {
  font-size: 1.5rem;
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
}

.downloadButton:hover {
  background-color: #580505;
}

.error {
  color: red;
  background-color: #fdd;
  border: 1px solid red;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
}