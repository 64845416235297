/* Dashboard.css */

.dashboard {
  background-color: #0E0E0E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column; /* Alteramos para column para centralizar o texto abaixo do logo */
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  background-color: #000;
  max-height: 100px;
}

.logo {
  width: 200px;
  margin-bottom: 20px; /* Adicionamos a margem abaixo do logo */
}

.dashboard-content {
  display: ;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto; /* Centraliza o conteúdo horizontalmente no desktop */
  padding: 50px;
padding-top: 00px;
  width: 100%; /* Para ocupar a largura total da tela no desktop */
  box-sizing: border-box; /* Incluímos as bordas e o padding na largura total */
}

.dashboard-left {
  flex: 0 0 400px;
  background-color: #444444;
  padding: 20px;
}

/* Removendo as bolinhas dos links */
.dashboard-left ul {
  list-style-type: none;
  padding: 0;
}

.dashboard-left a {
  display: block; /* Transforma os links em elementos de bloco */
  text-align: center; /* Centraliza o texto */
  font-size: 18px; /* Tamanho de fonte 18px */
  color: #000000; /* Cor do texto preto */
  background-color: #CCCCCC; /* Cor de fundo cinza mais claro */
  border-radius: 3px; /* Cantos arredondados em 3px */
  padding: 10px 20px; /* Espaçamento interno */
  margin-bottom: 20px; /* Distanciamento entre os botões */
  text-decoration: none; /* Remove sublinhado do link */
  transition: background-color 0.2s; /* Transição suave de cor no hover */
}

.dashboard-left a:hover {
  background-color: #DDDDDD; /* Cor de fundo mais clara no hover */
}

.dashboard-right {
  color: #ffffff;
  max-width: 600px;
  text-align: left;
  margin-right: 20px; /* Adicionamos uma margem à direita para aproximar do conteúdo da coluna esquerda */
}

.dashboard-right h2 {
  font-size: 20px; /* Alteramos para o tamanho 20px */
  margin: 20px 0; /* Alteramos a propriedade para margem em todos os lados */
  color: #ffffff; /* Definimos a cor branca para o texto */
}

.dashboard-right h3 {
  font-size: 18px; /* Alteramos para o tamanho 18px */
  margin-bottom: 10px;
}

.dashboard-right button {
  font-size: 20px;
  background-color: #FF0000;
  color: #FFFF00;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s; /* Adicionamos o valor para a transição */
  margin-top: 30px;
}

.dashboard-right button:hover {
  background-color: #CC0000;
}

/* Media query para dispositivos com largura menor ou igual a 768 pixels (exemplo: celulares) */
@media (max-width: 768px) {
  .dashboard-content {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-left {
    margin-bottom: 20px;
    flex: 1;
    width: 100%;
  }

  .dashboard-right {
    max-width: none;
    margin-right: 0; /* Removemos a margem à direita no mobile */
    text-align: center; /* Centraliza o texto no mobile */
  }

  /* Alterações para ajustar a ordem do texto e dos links no mobile */
  .dashboard-right h2 {
    order: 2;
  }

  .dashboard-right h3 {
    order: 1;
  }

  .welcome-text {
    font-size: 18px; /* Tamanho de fonte 18px */
    text-align: center; /* Centralizar o texto */
    margin: 20px 0; /* Margem em todos os lados */
  }
}


