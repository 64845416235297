.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100vh;
  text-align: center;
  color: #000000;
}

.h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #red;
  margin-bottom: 30px;
}

.logo {
  width: 200px;
  margin-bottom: 30px;
}

p {
  padding-top: 20px;
  padding-bottom: 20px;
}


.title {
  color: red;
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 35px;
}

.text {
  margin-bottom: 20px;
}

.text-bold {
  font-weight: bold;
  margin-bottom: 20px;
}

.text-thankyou {
  margin-bottom: 5px;
}

.button {
  color: #ffff00;
  background-color: #ff0000;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}

.footer-text {
  text-align: center;
  font-size: 18px;
  padding-top: 60px;
  padding-bottom: 20px;
  font-family: 'Montserrat Light', sans-serif;
}

.footer {
  text-align: center;
  margin-top: 50px;
}

.logoContainer {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo {
  width: 200px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 9px;
}



@media only screen and (max-width: 600px) {
  .container {
    margin: 40px;
  }
}


