.register-button {
  background-color: #FF0000;
  color: #FFFF00;
  border-radius: 0px;
  padding: 15px 50px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid #FF0000;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.register-button:hover {
  background-color: #000000;
  color: #FF0000;
  border: 2px solid #FF0000;
}
