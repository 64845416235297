/* Login.module.css */

.formContainer {
  background-color: #0E0E0E;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 0px); /* Altura total da tela menos 150px de espaço do início da tela */
  margin-top: -75px;
  padding-bottom: 150px;
}

.title {
  color: #ffffff; /* Defina a cor que desejar para o título */
  margin-top: 20px;
  margin-top: 20px;
  font-size: 28px
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


input {
  margin-bottom: 10px;
  padding: 10px;
}

button {
  padding: 10px 20px;
}

body {
  margin: 0;
  background-color: #ffffff; /* Cor de fundo preto para cobrir toda a tela */
}

button.login-button {
  font-size: 25px;
  background-color: #FF0000; /* Cor de fundo vermelha */
  color: #FFFF00; /* Cor do texto amarela */
  border: none;
  padding: 10px 55px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button.login-button:hover {
  background-color: #CC0000; /* Cor de fundo vermelha mais clara no hover */
}


