body {
  margin: 0;
}

.logoContainer {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

p {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}

.logo {
  width: 200px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 9px;
}

.container {
  text-align: center;
  margin-top: 50px;
  
}

.title {
  color: green;
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 35px;
}

.appLinks {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}

.appLogo {
  width: 200px;
  height: auto;
}

.boldText {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer-text {
  text-align: center;
  font-size: 18px;
  padding-top: 60px;
  padding-bottom: 20px;
  font-family: 'Montserrat Light', sans-serif;
}

.marginText {
  margin-top: 35px;
  margin-bottom: 45px;
}

.congratulationsImg {
  width: 300px;
  height: auto;
}

.footer {
  text-align: center;
  margin-top: 50px;
}




@media only screen and (max-width: 600px) {
  .container {
    margin: 40px;
  }
}

