

.container {
  width: 100%;
  padding-top: -20px
}

.gigyaContainer {
  display: block;
  min-height: 0;
  height: auto;
  border: none;
  margin: 0;
  padding: 30px;
  width: 100%;
  overflow: hidden;
}



.footer {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;

}

.combo-logo {
  width: 200px;
}

.footer-links {
  display: flex;
  
  gap: 20px;
  padding: 20px 0;
}

.footer-link {
  text-decoration: none;
  color: #000;
}

.footer-link-separator {
  padding: 0 10px;
}

.footer-text {
  padding-bottom: 20px;
}

.couponCard {
  background-color: #f5f5f5; /* Você pode escolher a cor de fundo do projeto aqui */
  border-radius: 15px;
  padding: 15px;
  text-align: center;
  margin: 25px auto;
  max-width: 400px;
  font-weight: bold;
}


.couponCard p {
  font-weight: bold;
}


