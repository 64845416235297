.logoContainer {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo {
  width: 200px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 9px;
}

.tryagain-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* alterado de 'height' para 'min-height' */
  text-align: center;
  background-color: #fff;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #red;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 2.5rem;
  font-weight: bold;
  color: #red;
  margin-bottom: 30px;
}

.text {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  color: black;
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 35px;
}

.button {
  width: 200px;
  padding: 1rem;
  font-size: 1.2rem;
  color: #ffff00;
  background-color: #ff0000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.button:hover {
  background-color: #B9463E;
}

.exitButton {
  width: 200px;
  padding: 1rem;
  font-size: 1.2rem;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 50px; /* Alterado de 2rem para 50px */
}

.exitButton:hover {
  background-color: #1a1a1a;
}

.redText {
  color: red;
}

/* Novos estilos responsivos */
@media screen and (max-width: 600px) {
  .title,
  .subtitle {
    font-size: 2rem; /* Tamanho de fonte reduzido para dispositivos móveis */
  }
  
  .button,
  .exitButton {
    width: 350px; /* Aumenta a largura dos botões para dispositivos móveis */
    font-size: 1rem; /* Diminui a fonte para dispositivos móveis */
  }

  .tryagain-container {
    margin: 40px;
  }
}

