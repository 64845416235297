.top-header {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: black;
}

.panini-logo {
  width: 250px; /* Define a largura do logo */
  margin-bottom: 5px;
}

.footer-text {
  text-align: center; /* centraliza o texto */
  font-size: 14px; /* define o tamanho da fonte */
  padding-top: 20px; /* adiciona um espaçamento superior */
  font-family: 'Montserrat Light', sans-serif; /* define a fonte */
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 250px;
}

@media (min-width: 992px) {
  .politica-de-cookies-container {
    padding: 40px;
    padding-left: 150px;
    padding-right: 150px;
    text-align: center;
  }
}

.politica-de-cookies-container h1 {
  font-family: 'Montserrat Black Italic', Arial, sans-serif;
  font-size: 2.1rem;
  color: black;
  margin-top: 30px;
}

.politica-de-cookies-container h2 {
  font-size: 1.4rem;
  margin-top: 5px;
  color: red;
}

.politica-de-cookies-container p {
  margin-top: 10px;
  margin-bottom: 30px;
}

/* Estilos para dispositivos móveis */
@media (max-width: 767px) {
  /* Margem de 20px de cada lado do texto */
  .text-container {
    padding: 20px;
   padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }

  /* Espaçamento após o header */
  .termos-e-condicoes-container {
    margin-top: 30px;
  }
}
