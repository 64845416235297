.header {
    position: fixed; /* Fixa o cabeçalho no topo da página */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100; 
    width: 100%;
    background-color: #000;
    color: #fff;
    z-index: 2; /* Adicionado para garantir que o cabeçalho esteja sempre acima do menu móvel */
  }
  
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .logo-links-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
    box-sizing: border-box;
    max-height: 40px;
  }
  
  .panini-logo {
    width: 250px;
    margin-top: 5px;
  }
  
  .header-navigation {
    display: flex;
    gap: 20px;
  }
  
  .header-link {
    color: #fff;
    text-decoration: none;
  }
  
  .header-link:visited {
    color: #ddd;
  }
  
  .link-text {
    font-size: 17px;
    margin-left: 5px;
  }
  
  
    @media screen and (max-width: 767px) {
    .logo-links-container {
      flex-direction: row;
      align-items: center;
      padding: 1px; /* Alterado para dar o mesmo padding em todos os lados */
      justify-content: space-between;
      max-height: 35px;
    }
  
    .panini-logo {
      max-width: 50px;
      margin-top: 5px;
      margin-left: 0px; /* Alinhado totalmente à esquerda */
    }
  
    .header-navigation {
      display: none;
    }
  
    .menu-button {
      background: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      color: #fff;
    }
  
   .header-link,
    .header-link-mobile {
      color: #fff;
      text-decoration: none;
    }
  
    .header-link:visited,
    .header-link-mobile:visited {
      color: #fff; /* Isso mantém o link branco mesmo depois de visitado */
    }
  
    .link-text {
      font-size: 20px;
      margin-left: 5px;
      }
  
    .mobile-menu {
    display: none;
    position: fixed; /* Mudado de absolute para fixed */
    top: 0; /* Alinhado com o topo da tela */
    right: 0; /* Alinhado com a direita da tela */
    height: 100%; /* Ocupa toda a altura da tela */
    width: 60%; /* Ocupa 60% da largura da tela */
    background-color: rgba(0, 0, 0, 0.9); /* Fundo preto com 90% de opacidade */
    color: #fff; /* Texto branco */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    overflow: auto; /* Adicionado para permitir a rolagem do conteúdo se for muito longo */
  }
  
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  .close-menu-button {
    position: left;
    top: 10px;
    right: 10px;
    background: black;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
  }
  
  
    .mobile-menu.open {
      display: flex;
    }
  
    .header-navigation {
      display: flex;
      gap: 30px;
    }
  
    .bm-burger-button {
      display: none;
    }
  
  .mobile-menu-link, .header-link {
      color: #fff;
      text-decoration: none;
    }
  
    .mobile-menu-link:visited, .header-link:visited {
      color: #fff;
    }
  
  }
  
  .main-content {
    padding-top: 70px; /* Ajuste esse valor de acordo com a altura do seu cabeçalho */
  }
  
  