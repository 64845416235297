/* Quiz.module.css */
* {
  box-sizing: border-box;
  margin: 100%;
  padding: 100%;
}

body,
html {
  width: 100%;
  height: 100%;
  
  

  overflow-x: hidden;
  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
  
}

.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;  /* full width */
  min-height: 950px;
  background-color: #171717;
  color: #fff;
  padding: 50px;
  border-radius: 0px;
  box-sizing: border-box;
  overflow: auto;
  margin-top: -75px;
}

.quiz-title {
    margin-top: 70px;
  }

.answer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  margin-bottom:50px;
}

.answer-button {
  color: #fff;
  margin-top: 10px;
  padding: 20px;
  border-radius: 10px;
  flex-grow: 1;
  text-align: center;
  font-family: 'Montserrat Light', sans-serif !important;
  font-size: 20px !important;
  font-weight: bold;
  min-height: 70px;
}

.correto {
  color: green;
}

.incorreto {
  color: red;
}

.message {
  background-color: ;
  height: 100%; 
  padding-top: -200px;


 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;  /* full width */
  height: 100%;
  background-color: #171717;
  border-radius: 0px;
  box-sizing: border-box;
  overflow: auto;
}

@media (min-width: 768px) {
  .answer-button {
    width: 300px;
  }
}

.question-text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.timer {
  margin-top: 50px;
}

.question-number {
  margin-top: 20px;
}

.attempt-number {
  margin-top: 20px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .quiz-container {
    width: 100%;
   
  
    margin: 0;
    padding: 40px 20px;
    padding-top: 30px;
    border-radius: 0;
    box-sizing: border-box;
  }

.quiz-title {
    margin-top: 40px;
  }

  .answer-container {
    flex-direction: column;
    gap: 25px;
  }

  .answer-button, .question-text {
    font-size: 19px !important;
     min-width: 250px;
  }

  .timer, .question-number, .attempt-number {
    font-size: 16px !important;
  }
}

.footer-text {
  text-align: center; /* Centraliza o texto */
  font-size: 18px; /* Define o tamanho da fonte */
  padding-top: 20px; /* Adiciona um espaçamento superior */
  padding-bottom: 20px; /* Adiciona um espaçamento inferior */
  font-family: 'Montserrat Light', sans-serif; /* Define a fonte */
}

