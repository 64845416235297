.header {
  background-color: black;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 200px;
}

.space {
  height: 80px;
}

.h1 {
  font-size: 40px;
  text-align: center;
}

.message {
  font-size: 30px;
  text-align: center;
}

@media (max-width: 768px) {
  .message {
    margin: 0 40px;
  }

  .h1 {
    margin: 0 40px;
  }
}


.h1 {
  font-size: 40px;
  text-align: center;
  color: red; /* Adicione esta linha */
}

