.inicio-container {
  background-color: #171717;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

h1 {
  color: #fff;
  text-align: center;
}

.subtitle {
  color: #f00;
  text-align: center;
  margin-bottom: 40px;
}

.info-paragraph {
  text-align: center;
  margin-bottom: 20px;
}

.start-button {
  background-color: #f00;
  color: #ff0;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 300px;
}

.panini-logo {
  width: 250px; /* Define a largura do logo */
  margin-bottom: 5px;
}


/* Adicione esta nova classe CSS */
.welcome-text {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #FFE400;
  margin-top: 180px;
  margin-bottom: 30px;
  font-family: 'Montserrat Black Italic', sans-serif;
}

/* Ajustes para telas menores que 768px de largura (tipicamente tablets e celulares) */
@media (max-width: 768px) {
  .inicio-container {
     /* 2.5% de preenchimento em ambos os lados */
  }

.info-paragraph {
  text-align: center;
  margin-bottom: 20px;
  padding: 0 2.5%;
}

.welcome-text {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  color: #FFE400;
  font-family: 'Montserrat Black Italic', sans-serif;
}

h1 {
  color: #fff;
  text-align: center;
  font-size: 25px;
  padding: 0 2.5%;
  
}


.start-button {
  background-color: #f00;
  color: #ff0;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 300px;
  font-size: 25px;
}

.subtitle {
  color: #f00;
  text-align: center;
  margin-bottom: 40px;
  font-size: 25px;
}
}
