/* Home.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Montserrat Light';
  src: url('../fonts/Montserrat-Light.otf') format('opentype');
}


body {
  background-color: #ffffff;
  font-family: 'Montserrat Light', sans-serif;
  font-size: 1.3rem;
  padding-top: 75px;
}



.top-header {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: black;
}

.panini-logo {
  height: 50px;
  margin: auto;
}

.main-title, .section-title {
  color: #000;
  font-family: 'Montserrat Black', sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 50px 20px;

}

.red-text {
  color: red;
}

.paragraph, .right-column-text, .custom-list {
  font-family: 'Montserrat Light', sans-serif;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #000;
  padding-right: 20%;
  padding-left: 20%;
  margin-bottom: 50px;
}

.row {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

.column {
  flex: 50%;
}

.stadium-image {
  width: 80%;
  image-align: center;
margin-left: 60px;
margin-bottom: 60px;
}

.title {
  font-family: 'Montserrat Black', sans-serif;
  color: red;
  text-align: center;
}

.right-column-text {
  padding-top: 20px;
  margin-left: -190px;
}

.custom-list {
  list-style-type: none;
  padding: 0;
}

.custom-list li:before {
  content: url('https://upload.wikimedia.org/wikipedia/commons/0/03/Red_check.svg');
  padding-right: 5px;
}

.background-gray {
  background-color: #f2f2f2;
}

.admission-test {
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.admission-test .register-button {
  font-size: 2.3em;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: red;
  color: yellow;
  border: none;
  padding: 10px 60px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
}

.admission-test .register-button:hover {
  background-color: black;
  border: 2px solid red;
text-align: center;
  color: red;

}

.project-information {
  background-color: #f2f2f2;
  text-align: center;
  font-size: 12px;
  padding: 20px 0;
}

.combo-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}

h1, h2, h3, h4, h5, h6, button {
  font-family: 'Montserrat Black Italic', sans-serif;
  font-size: 2.5rem;
text-align: center;
}

@font-face {
  font-family: 'Montserrat Black Italic';
  src: url('../fonts/Montserrat-BlackItalic.otf') format('opentype');
}

.main-title {
  color: #000;
  font-family: 'Montserrat Black Italic', sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 50px 20px;
}

.section-title {
  color: red;
  text-align: center;
  font-size: 1.8rem;
  font-family: 'Montserrat Black Italic', sans-serif;
  margin-top: 30px;
}

.app-mockup {
  width: 100%;
  height: auto;
}

.collaboration-title {
  font-family: 'Montserrat Black Italic', sans-serif;
  color: black;
  text-align: left;
  font-size: 1.8rem;
}

.centered-text {
  text-align: center;
}

.centered-text br {
  display: block;
  margin: 10px 0;
}

.custom-list li:before {
  content: "\25BA"; /* Código unicode para o símbolo de seta "►" */
  margin-right: 5px; /* Espaçamento entre o ícone e o texto */
}


.content-row {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  margin: 20px 80px;
}

.content-column {
  flex-basis: 45%;
  }

.content-box {
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 20px;
  padding-right: 20px;
  background-color: #ffffff;
}

.content-box-inner {
  display: flex;
}

.number-img {
  width: 80px;
  height: 80px;
  margin-right: 30px;
}

.content-box-inner p {
  flex-grow: 1;
}

.center-text {
  text-align: center;
}

@media screen and (max-width: 820px) {
  .content-row {
    flex-direction: column;
    align-items: center;
  }
  .content-column {
    margin-bottom: 20px;
    width: 100%;  /* Faz com que o texto ocupe toda a largura do container */
  }
  .content-column img {
    display: block; /* Faz com que a imagem fique acima do texto */
    margin: 0 auto; /* Centraliza a imagem */
  }
  .content-column p {
    margin-top: 10px; /* Adicione um pouco de espaço entre a imagem e o texto */
  }
.stadium-image {
  width: 80%;
  image-align: center;
margin-left: 60px;
}

.right-column-text {
  padding-top: 20px;
  margin-left: 0px;
}
.paragraph, .right-column-text, .custom-list {
  font-family: 'Montserrat Light', sans-serif;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #000;
  padding-right: 9%;
  padding-left: 9%;
  margin-bottom: 50px;}

.collaboration-title {
  font-family: 'Montserrat Black Italic', sans-serif;
  color: black;
  text-align: center;
  font-size: 1.8rem;
  margin-top: 20px;
}
}

.content-box-inner-mobile {
  flex-direction: column;
  margin: 0 auto;

}

.content-box-inner-mobile img {
  margin: 0 auto;
}

.content-box-inner-mobile p {
  text-align: center;
  margin-top: 15px;
  width: 100%;
}

.black-section {
  background-color: black;
  padding-bottom: 40px;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}


.black-section .white-text {
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.8rem;
}

.partners-text {
  margin-top: 20px;
 font-size: 14px;
  text-align: center;
  color: #000;  /* Altere para a cor desejada */
  font-family: 'Montserrat Light', sans-serif;  /* Altere para a fonte desejada */
}

.container {
        flex-direction: column;
       
    }

    .col {
        width: 100%;
        padding: 0;
    }

@media (max-width: 820px) {
  .row-mobile {
    flex-direction: column-reverse;
  }

  .mobile-collab .column {
    display: flex;
    flex-direction: column;
  }

  .mobile-collab .column.d-flex {
    order: 2;
  }

  .mobile-collab .column .column-text {
    order: 1;
  }
}

@media (max-width: 768px) {
  .combo-logo {
    width: 60%;
  }

.footer-text {
  text-align: center; /* centraliza o texto */
  font-size: 14px; /* define o tamanho da fonte */
  padding-top: 20px; /* adiciona um espaçamento superior */
  font-family: 'Montserrat Light', sans-serif; /* define a fonte */
}

}

.footer-text {
  text-align: center; /* centraliza o texto */
  font-size: 18px; /* define o tamanho da fonte */
  padding-top: 20px; /* adiciona um espaçamento superior */
padding-bottom: 20px; /* adiciona um espaçamento superior */
  font-family: 'Montserrat Light', sans-serif; /* define a fonte */
}

.regulamento {
  text-align: center; /* centraliza o texto */
  font-size: 18px; /* define o tamanho da fonte */
  padding-top: 20px; /* adiciona um espaçamento superior */
padding-bottom: 20px; /* adiciona um espaçamento superior */
  font-family: 'Montserrat Light', sans-serif; /* define a fonte */
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.cookie-policy-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 4px;
  outline: none;
  opacity: 1 !important;
  width: 100%;
  box-sizing: border-box;
}

.modal-content {
  display: flex;
  justify-content: space-between;  /* Alinha o texto e o botão nas extremidades opostas */
  padding: 30px 20px 20px;
  text-align: left;
  font-size: 20px !important;
  color: #fff !important;
  background: #000;
}

.cookie-policy-modal button {
  display: inline-block;
  margin-top: 0;  /* Removendo a margem do topo */
  margin-left: 20px; 
  padding: 10px 20px;
  border: none;
  background: #252627;
  color: #fff;
  cursor: pointer;
  font-weight: normal !important;
  font-size: 18px !important;
  font-family: 'Montserrat Light', sans-serif;
}

.modal-content .cookie-link:link,
.modal-content .cookie-link:visited {
  color: white;
}

.modal-content .cookie-link:hover {
  color: grey;
}


footer-link:link, .footer-link:visited {
  color: black;
  text-decoration: none;
}

.footer-link:hover {
  color: grey;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top: 10px;
}

.footer-link-separator {
  margin: 0 10px;
}


@media (max-width: 820px) {
  .modal-content {
    flex-direction: column;  /* Faz o texto e o botão se alinharem verticalmente */
    padding: 20px 10px 10px;
  }
  .cookie-policy-modal button {
    margin-top: 20px;  /* Coloca a margem do topo de volta */
  }

 .footer-links {
    flex-direction: column;
  }

  .footer-link-separator {
    display: none;
  }
}

.age-prompt-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px; 
  background-color: black;
  border: 1px solid #000000;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homePopup {
  border-radius: 20px;
}

.age-prompt-modal .modal-content {
  text-align: center;
}

.age-prompt-modal .acceptButton {
  margin-top: 10px;
  background-color: #252627;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: normal !important;
  font-size: 24px !important;
  font-family: 'Montserrat Light', sans-serif;
  border-radius: 20px;
}

.acceptButton {
  border-radius: 20px;
}

.modal-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}