/* CupomManager.module.css */

.dashboard {
  background-color: #0E0E0E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  margin-top: -75px;
}

.logo {
  width: 200px;
  margin-bottom: 20px;
}

.dashboard-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
}

.content-box, .searchBox {
  max-width: 400px;
  width: 100%;
  background-color: #444444;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  flex: 1;
}

.form-row {
  margin-bottom: 20px;
}

.form-row label {
  display: block;
  margin-bottom: 5px;
  color: #ffffff;
}

.form-row input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.form-row button {
  background-color: #F7F7F8;
  border: none;
  color: #000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.form-row button:hover {
  background-color: #DDDDDD;
}

.voltar {
  background-color: red;
  border: none;
  color: yellow;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  width: auto;
}

.voltar:hover {
  background-color: #CC0000;
}

.question-text {
  color: #ffffff;
  margin-top: 20px;
}

.button {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

/* Estilos individuais para cada botão */
.button.manual {
  background-color: #F7F7F8;
  color: #000;
}

.button.manual:hover {
  background-color: #DDDDDD;
}

.button.upload {
  background-color: #F7F7F8;
  color: #000;
}

.button.upload:hover {
  background-color: #DDDDDD;
}

.button.generate {
  background-color: #F7F7F8;
  color: #000;
  margin-left: 10px;
}

.button.generate:hover {
  background-color: #DDDDDD;
}

.coupon-info {
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}


@media (max-width: 768px) {
  .dashboard-content {
    flex-direction: column;
    align-items: center;
  }

  .content-box, .searchBox {
    max-width: 100%;
  }
}

.answer {
  font-size: 20px;
}

.question-text h2 {
  font-size: 20px;
}

