/* UserList.module.css */
.dashboard {
  background-color: #0E0E0E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  margin-top: -75px;
}

.logo {
  width: 200px;
  margin-bottom: 20px;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
}

.user-card {
  background-color: #444444;
  color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.user-card h2 {
  color: #ffffff;
  margin-bottom: 10px;
}

.user-card p {
  color: #ffffff;
  margin-bottom: 5px;
}

.voltar {
  background-color: red;
  border: none;
  color: yellow;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  width: auto;
  margin-bottom: 20px;
}

.voltar:hover {
  background-color: #CC0000;
}

/* Novo estilo para a caixa de busca */
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.search-text {
  display: none; /* Esconde o texto por padrão */
  color: white;
  font-weight: bold;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #c60000;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
  margin-bottom: 20px;
}

.pagination-button:hover {
  background-color: ##c60000;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.users-per-page {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.users-per-page-text {
  color: white;
  margin-right: 10px;
}

.users-per-page-button {
  background-color: #383838;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 5px;
}

.users-per-page-button.active {
  background-color: #c60000;
}

@media (min-width: 769px) {
  /* Para telas maiores que 768px (desktop) */
  .search-text {
    display: block; /* Exibe o texto para desktop */
    margin-right: 20px;
  }

  .search-box {
    flex: 1; /* Ocupa o espaço restante no desktop */
    max-width: 400px; /* Define o tamanho máximo da caixa de busca */
  }
}

.user-card {
  /* ... */
  position: relative; /* Adiciona posicionamento relativo para que o botão de deletar fique posicionado dentro do card */
}

.delete-button {
  background-color: #CC0000;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute; /* Adiciona posicionamento absoluto para que o botão de deletar fique posicionado ao lado dos emails */
  bottom: 10px; /* Ajusta a posição vertical do botão (10px de distância do final do card) */
  right: 20px; /* Ajusta a posição horizontal do botão (20px de distância da direita do card) */
}

.delete-button:hover {
  background-color: #FF0000;
}

@media (max-width: 768px) {
  /* Para telas menores ou iguais a 768px (mobile) */
  .search-text {
    display: block; /* Exibe o texto para mobile */
    margin-bottom: 10px;
  }

  .search-box {
    width: 100%; /* Ocupa todo o espaço disponível no mobile */
    max-width: 100%; /* Define o tamanho máximo da caixa de busca */
  }
.delete-button {
    position: relative; /* Altera para posicionamento relativo no mobile para que o botão fique abaixo do cupom */
    bottom: initial; /* Remove o posicionamento vertical do botão no mobile */
    right: initial; /* Remove o posicionamento horizontal do botão no mobile */
    display: block; /* Faz o botão ocupar a largura total do card no mobile */
    margin-top: 10px; /* Adiciona um espaçamento acima do botão no mobile */
  }
}



